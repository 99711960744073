import { default as _91id_93hcdKUq0lrfMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexrcfLkULiHwMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_932pPJfQG0MIMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexIt0IpxS6kEMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsXov14kK11tMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variants4g8LlvkGiGMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexHfmcXuBT2NMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexILHrryC7JrMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboardPyCT7sYRibMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceduOwa1CTlsMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/entrance.vue?macro=true";
import { default as indexoBjPjf9IpEMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/index.vue?macro=true";
import { default as loginhd8JTM7D3qMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/login.vue?macro=true";
import { default as indexafM4DjcKeiMeta } from "/home/forge/merchants.smake.com/releases/v1.39.0/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93hcdKUq0lrfMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/catalog/logos/[id].vue")
  },
  {
    name: "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexrcfLkULiHwMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/catalog/logos/index.vue")
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_932pPJfQG0MIMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[customer]/logos/[id].vue")
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexIt0IpxS6kEMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[customer]/logos/index.vue")
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsXov14kK11tMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[id]/colors.vue")
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variants4g8LlvkGiGMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[id]/favorite-variants.vue")
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexHfmcXuBT2NMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/[id]/index.vue")
  },
  {
    name: "slug-customers",
    path: "/:slug()/customers",
    meta: indexILHrryC7JrMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/customers/index.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceduOwa1CTlsMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/entrance.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginhd8JTM7D3qMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/[slug]/login.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.39.0/pages/index.vue")
  }
]